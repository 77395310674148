import i18next from "i18next/index";
import {getUserParam} from "./users";

export const setLanguage = (language,jwtToken = false) => {
    i18next.init({
        lng: language,
        resources: require(`./../locales/${language}.json`),
    });
    //если авторизиван, получить фио на нужном языке и вернуть для устанки параметров юзера в стейт
    /*if(jwtToken) {
        getUserParam(jwtToken, language).then(item => {
            this.setState({userId: item});
        });
    }*/
};
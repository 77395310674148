import queryString from 'query-string'
import {
    languageArr,
    monthPay_range,
    price_range,
    url_params,
} from '../constants/params'
import * as filter from './filter_param'

export const getUrlParam = (url) => {
    const pars = queryString.parse(url)
    const arr = Object.keys(pars)[0]
    let param = arr.split('/')
    param.shift()
    return param
}

export const getLangFromUrlParam = (params) => {
    const lang = params[0]
    return lang && languageArr.includes(lang) ? lang : languageArr[0]
}

export const getLangToUrl = (lang) => {
    return lang === languageArr[1] ? lang : ''
}

export const urlId = (result, params, userId) => {
    if (userId) {
        const i = params.indexOf('id')
        if (i !== -1) {
            if (params[i + 1]) {
                const id = parseInt(params[i + 1], 10)
                if (Number.isInteger(id)) {
                    result.idAuto = id
                    params.splice(i + 1, 1)
                }
            }
            params.splice(i, 1)
        }
    }
}

export const urlType = (result, params, arr) => {
    const i = params.indexOf('cars')
    if (i !== -1) {
        urlArrayParams(result, params, arr, 'type')
        params.splice(i, 1)
    }
}

export const urlCity = (result, params) => {
    const index = params.indexOf('city')
    if (index !== -1) {
        if (params[index + 1]) {
            result.region_param.map((item) => {
                if (item.title === params[index + 1]) {
                    result.region = item.value
                    params.splice(index + 1, 1)
                }
            })
        }
        params.splice(index, 1)
    }
}

export const urlSelectParam = (result, params) => {
    result.bodyType_param.map((item) => {
        //const index = params.indexOf(item.label);
        const index = params.indexOf(item.title)
        if (index !== -1) {
            result.bodyType = item.value
            params.splice(index, 1)
        }
    })
}

export const urlMarkaModel = (result, params) => {
    result.marka_param.map((item) => {
        const index = params.indexOf(item.label)
        if (index !== -1) {
            result.marka = item.value
            params.splice(index, 1)
        }
    })
}

export const urlModel = async (result, params, marka, access, lang) => {
    if (marka !== 0) {
        const model = await filter.getElementModel(marka, access, lang)
        result.model_pram = model
        model.map((item_model) => {
            const index2 = params.indexOf(item_model.title)
            if (index2 !== -1) {
                result.model = item_model.value
                params.splice(index2, 1)
            }
        })
    }
}

export const urlFromTo = (
    result,
    params,
    name,
    flag = 0,
    arr = false,
    name_real = false
) => {
    const index1 = params.indexOf(`${name}_from`)
    if (index1 !== -1) {
        if (params[index1 + 1]) {
            let val = flag
                ? parseFloat(params[index1 + 1])
                : parseInt(params[index1 + 1])
            if (arr) {
                val = val >= arr.min && val <= arr.max ? val : arr.min
            }
            result[name_real ? name_real : name] = {
                ...result[name_real ? name_real : name],
                from: val,
            }
            params.splice(index1 + 1, 1)
        }
        params.splice(index1, 1)
    }
    const index2 = params.indexOf(`${name}_to`)
    if (index2 !== -1) {
        if (params[index2 + 1]) {
            let val = flag
                ? parseFloat(params[index2 + 1])
                : parseInt(params[index2 + 1])
            if (arr) {
                val = val >= arr.min && val <= arr.max ? val : arr.max
            }
            result[name_real ? name_real : name] = {
                ...result[name_real ? name_real : name],
                to: val,
            }
            params.splice(index2 + 1, 1)
        }
        params.splice(index2, 1)
    }
}
export const urlArrayParams = (result, params, arr, name) => {
    arr.map((item) => {
        const index = params.indexOf(item.title)
        if (index !== -1) {
            result[name] = { ...result[name], [item.title]: 1 }
            params.splice(index, 1)
        } else {
            result[name] = { ...result[name], [item.title]: 0 }
        }
    })
}

export const urlSort = (result, params) => {
    //result.sort = result.sort_param[1].value;
    const index = params.indexOf('sort')
    if (index !== -1) {
        if (params[index + 1]) {
            result.sort_param.map((item) => {
                const index1 = params.indexOf(item.value)
                if (index1 !== -1) {
                    result.sort = item.value
                    params.splice(index1, 1)
                }
            })
        }
        params.splice(index, 1)
    }
}

export const urlUtmSource = (result, params) => {
    const index = params.indexOf('utm_source')
    if (index !== -1) {
        if (params[index + 1]) {
            result.utm_source = params[index + 1]
            params.splice(index + 1, 1)
        }
        params.splice(index, 1)
    }
}

export const setAllParam = (param_http) => {
    let result = { ...url_params }
    result.region_param = param_http.region
    result.bodyType_param = param_http.bodyType
    result.marka_param = param_http.marka
    result.fuel_param = param_http.fuel
    result.transmission_param = param_http.transmission
    result.typeDrive_param = param_http.typeDrive
    result.equipment_param = param_http.equipment
    result.type_param = param_http.type
    result.sort_param = param_http.sort
    result.year_min = param_http.year_min

    return result
}

export const getUrlParams = async (param, param_http, access, lang, userId) => {
    let result = { ...param_http }

    urlId(result, param, userId)
    urlArrayParams(result, param, result.fuel_param, 'fuel')
    urlArrayParams(result, param, result.transmission_param, 'transmission')
    urlArrayParams(result, param, result.typeDrive_param, 'typeDrive')
    urlArrayParams(result, param, result.equipment_param, 'equipment')
    urlType(result, param, result.type_param)
    urlFromTo(result, param, 'year')
    urlFromTo(result, param, 'run')
    urlFromTo(result, param, 'volume', 1)
    urlFromTo(result, param, 'price', 0, price_range)
    urlFromTo(result, param, 'month_pay', 0, monthPay_range, 'monthPay')

    urlCity(result, param)
    urlSelectParam(result, param)
    urlMarkaModel(result, param)
    await urlModel(result, param, result.marka, access, lang)
    urlSort(result, param)
    if (Object.keys(result.type).length === 0) {
        result.type['in-use'] = 1
        result.type['new'] = 1
        // result.type['bankcar'] = 1;
    }
    urlUtmSource(result, param)
    return result
}

import { access_url } from '../constants/config'
import { httpGet, httpPost } from './access'
import { rolePromin } from '../constants/params'

export const getIp = () => {
    //https://newauto.test.it.loc/api/checkIp
    const url = access_url + 'checkIp'

    return httpGet(url, '').then((i) => {
        if (!i || !i.data) return false
        return i.data.local === true ? true : false
    })
}

export const getUserParam = (token, lang) => {
    //https://newauto.test.it.loc/api/auth/getUserInfo
    //Authorization: Bearer
    const url = access_url + 'auth/getUserInfo?lang=' + lang
    return httpGet(url, '', 'Authorization', 'Bearer ' + token).then((i) => {
        if (!i) return false
        return i.data ? i.data : false
    })
}

export const getLogout = (token) => {
    //https://newauto.test.it.loc/api/auth/logout
    const url = access_url + 'auth/logout'
    return httpGet(url, '', 'Authorization', 'Bearer ' + token).then((item) => {
        //return item;
    })
}

export const getRole = (param) => {
    return rolePromin.find((x) => x === param) ? true : false
}

export const setBuy = (access, name, phone, utm, userId) => {
    //https://newauto.test.it.loc/api/buyCar
    /*{
        "name": "Mikhail Vasilev",
        "phone": "+38 (063) 382-51-41"
    }*/
    const url = access_url + 'buyCar'
    let param = {
        name: name,
        phone: phone,
        agent: userId && userId.login ? userId.login : utm ? utm : 'autopbua',
    }
    return httpPost(url, access, '', param).then((item) => {})
}

export const setSell = (
    access,
    name,
    phone,
    marka,
    model,
    year,
    region,
    sell
) => {
    //https://newauto.test.it.loc/api/sellCar
    /*{
        "sellBrand": 1,
        "sellModel": 1,
        "sellYear": 2007,
        "sellRegion": 12,
        "sellName": "Mikhail Vasilev",
        "sellPhone": "+38 (063) 382-51-41"
    }*/
    const url = access_url + 'sellCar'
    let param = {
        sellBrand: marka,
        sellModel: model,
        sellYear: year,
        sellRegion: region,
        sellName: name,
        sellPhone: phone,
    }
    if (sell) {
        param = { ...param, sellCarCity: sell }
    }
    return httpPost(url, access, '', param).then((item) => {})
}

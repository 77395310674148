import i18next from 'i18next/index'
import { monthPay_range, price_range } from '../constants/params'
import { access_url } from '../constants/config'
import { httpGet } from './access'

export const getElementsChekbox = (params, name, arr) => {
    let result = []
    arr.map((item) => {
        const val = {
            id: item.title,
            name: name,
            value: params[name][item.title],
            default: params[name][item.title] === 1,
            label: item.label,
            col: null,
        }
        result.push(val)
    })
    return result
}

const setSelectedElement = (val, params) => {
    return val.map((el) => {
        let new_el = el
        if (el.value === params) new_el.selected = 1
        else new_el.selected = 0
        return new_el
    })
}

export const setElementRegion = (params) => {
    const val = []
    params.region_param.map((item) => {
        val.push(item)
    })
    return setSelectedElement(val, params.region)
}

export const setElementBodyType = (params) => {
    const val = []
    params.bodyType_param.map((item) => {
        val.push(item)
    })
    return setSelectedElement(val, params.bodyType)
}

export const getElementApi = (access, url) => {
    return httpGet(url, access).then((i) => {
        if (!i) return false
        return i.map((item) => {
            return { value: item.id, title: item.label, label: item.title }
        })
    })
}
export const setElementMarka = (params) => {
    const val = []
    params.marka_param.map((item) => {
        val.push(item)
    })
    return setSelectedElement(val, params.marka)
}

export const getElementModel = (marka, access, lang) => {
    if (marka === 0) return false
    const url =
        access_url +
        'getModelsByBrand?lang=' +
        lang +
        '&id_brand=' +
        marka +
        '&with_cars=1'
    return getElementApi(access, url)
}

export const setElementModelParam = (params) => {
    if (params.marka === 0) return false
    //const val = [{value: 0, label:i18next.t('all_marka'), title: ''}];
    const val = []
    if (params.model_pram) {
        params.model_pram.map((item) => {
            val.push(item)
        })
    }
    return setSelectedElement(val, params.model)
}

export const getElementYearFrom = (year_min) => {
    let val = [{ value: '', label: i18next.t('sell_year_small_from') }]
    for (let i = year_min; i <= new Date().getFullYear(); i++) {
        val.push({ value: i, label: i18next.t('from_year') + ' ' + i })
    }
    return val
}
export const setElementYearFrom = (params) => {
    const val = getElementYearFrom(params.year_min)
    return setSelectedElement(val, params.year.from)
}

export const getElementYearTo = (year_min) => {
    let val = [{ value: '', label: i18next.t('sell_year_small_to') }]
    for (let i = year_min; i <= new Date().getFullYear(); i++) {
        val.push({ value: i, label: i18next.t('to_year') + ' ' + i })
    }
    return val
}
export const setElementYearTo = (params) => {
    const val = getElementYearTo(params.year_min)
    return setSelectedElement(val, params.year.to)
}

export const getElementYear = (year_min) => {
    let val = [{ value: '', label: i18next.t('sell_year') }]
    for (let i = year_min; i <= new Date().getFullYear(); i++) {
        val.push({ value: i, label: i + i18next.t('placehold_year') })
    }
    return val
}

export const setElementYear = (params) => {
    const val = getElementYear(params.year_min)
    return setSelectedElement(val, params.year.from)
}

export const setElement10Year = (params) => {
    const val = getElementYear(new Date().getFullYear() - 10)
    return setSelectedElement(val, params.year.from)
}

//---------------------------------------------------//
export const getElementsInput = (params) => {
    return params.idAuto
}

export const getElementRunFrom = (params) => {
    return parseInt(params.run.from)
}

export const getElementRunTo = (params) => {
    return parseInt(params.run.to)
}

export const getElementVolumeFrom = (params) => {
    return parseInt(params.volume.from)
}

export const getElementVolumeTo = (params) => {
    return parseInt(params.volume.to)
}

export const getElementPriceFrom = (params) => {
    return params.price.from
}

export const getElementPriceTo = (params) => {
    return params.price.to
}

export const getElementMonthPayFrom = (params) => {
    return params.monthPay.from
}

export const getElementMonthPayTo = (params) => {
    return params.monthPay.to
}

export const getShowElementInput = (params, name) => {
    return params[name] !== 0 ? true : false
}

export const getShowElementCheck = (params, name) => {
    return Object.values(params[name]).find((item) => {
        return item === 1
    })
        ? true
        : false
}

export const getShowElementSelect = (params, name) => {
    return params[name] ? true : false
}

export const getShowElementSelectTwo = (params, name) => {
    return Object.values(params[name]).find((item) => {
        return parseFloat(item) > 0
    })
        ? true
        : false
}

export const getShowElementRangeslider = (params, name) => {
    let val = {}
    if (name === 'price') val = price_range
    else if (name === 'monthPay') val = monthPay_range
    return (
        Object.values(params[name])[0] !== Object.values(val)[0] ||
        Object.values(params[name])[1] !== Object.values(val)[1]
    )
}

export const setInitialParams = (params, val) => {
    let newState = { ...params }
    if (val === '') {
        params.type_param.map((item) => {
            if (item.title === 'in-use' || item.title === 'new') {
                newState.type = { ...newState.type, [item.title]: 1 }
            } else {
                newState.type = { ...newState.type, [item.title]: 0 }
            }
        })
    } else {
        params.type_param.map((item) => {
            if (item.title === val) {
                newState.type = { ...newState.type, [val]: 1 }
            } else {
                newState.type = { ...newState.type, [item.title]: 0 }
            }
        })
    }
    params.fuel_param.map((item) => {
        newState.fuel = { ...newState.fuel, [item.title]: 0 }
    })
    params.transmission_param.map((item) => {
        newState.transmission = { ...newState.transmission, [item.title]: 0 }
    })
    params.typeDrive_param.map((item) => {
        newState.typeDrive = { ...newState.typeDrive, [item.title]: 0 }
    })
    params.equipment_param.map((item) => {
        newState.equipment = { ...newState.equipment, [item.title]: 0 }
    })
    newState.idAuto = 0
    newState.marka = 0
    newState.model = 0
    newState.region = 0
    newState.bodyType = 0
    newState.year = { from: 0, to: 0 }
    newState.price = { from: price_range.min, to: price_range.max }
    newState.monthPay = { from: monthPay_range.min, to: monthPay_range.max }
    newState.run = { from: 0, to: 0 }
    newState.volume = { from: 0, to: 0 }
    newState.sort = 'date'
    return newState
}

export const getCityByRegion = (access, lang, id) => {
    //https://newauto.test.it.loc/api/getCitiesByRegion?lang=ru&id_region=3
    const url =
        access_url + 'getCitiesByRegion/?lang=' + lang + '&id_region=' + id
    return httpGet(url, access).then((item) => {
        if (!item || item.length === 0) return false
        return item /*i.map(
            item=>{
                return{value: item.id, title: item.label, label: item.title}
            })*/
    })
}

// actions
export const SETLANG = 'SETLANG';
//export const INCREASE = 'INCREASE'
//export const DECREASE = 'DECREASE'

// Создаем action creators
export const setLang =(lang) => ({
        type: SETLANG,
        payload: lang
})

/*
export function increase() {
    return {
        type: INCREASE
    }
}
export function decrease() {
    return {
        type: DECREASE
    }
}*/

import {
    access_key,
    access_login,
    access_password,
    access_url,
} from '../constants/config'
import queryString from 'query-string'
import https from 'https'
import http from 'http'
const agentHttps = new https.Agent({
    rejectUnauthorized: false,
})
const agentHttp = new http.Agent({
    rejectUnauthorized: false,
})
const fetch = require('node-fetch')

export const getAccess = (id) => {
    //acces.php?id=5e6f58532530e0.03652718&login=secret_name&pass=secret_pass
    const url = access_url + 'acces.php'
    const param = queryString.stringify({
        id: id,
        login: access_login,
        pass: access_password,
    })
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: param,
    })
        .then((res) => res.json())
        .then(
            (result) => {
                return result
            },
            (error) => {
                return []
            }
        )
}

export const setAccessHeader = () => {
    let jwt = require('jsonwebtoken')
    //return jwt.sign({}, access_key, { expiresIn: '1h' });
    return jwt.sign({ iat: Math.floor(Date.now() / 1000) - 3600 }, access_key)
}

export const httpGet = (url, access, key = false, val = false) => {
    let header = {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authentication: access,
    }
    if (key && val) {
        header[key] = val
    }

    return fetch(url, {
        method: 'GET',
        agent: process.env.MODE === 'test' ? agentHttp : agentHttps,
        headers: header,
    }).then(
        (res) => {
            if (res.status === 200) return res.json()
            else return { error: res.status }
        },
        (error) => {
            return false
        }
    )
}

export const httpPost = (url, access, auth, param) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authentication: access,
    }
    if (auth !== '') {
        headers.Authorization = auth
    }
    return fetch(url, {
        method: 'POST',
        headers: headers,
        agent: process.env.MODE === 'test' ? agentHttp : agentHttps,
        body: JSON.stringify(param),
    }).then(
        (res) => {
            if (res.status === 200) return res.json()
            else return { error: res.status }
        },
        (error) => {
            return false
        }
    )
}

//import { INCREASE, DECREASE, GETLANG } from './actions'
import { SETLANG } from './actions'
//import {getIpQ} from './../app/library/helper';

export let defaultState = {};

const initialState = {
    lang: 'ua',
    //count: 5,
    //ip: '---'
}
export default function ip_reduc(state = initialState, action) {
//export const ip_reduc = (state = defaultState,action) => {
    if(!action || !action.type) {
        return state;
    }
    switch (action.type) {
        case SETLANG:
            return {
                ...state,
                lang: action.payload
            };
            /*return Object.assign(({}, state, {
                lang: 't'
            }))*/
        /*case INCREASE:
            // Если наш action = INCREASE - увеличиваем state.count на 1
            return Object.assign({}, state, {
                count: state.count + 1
            })
        case DECREASE:
            // Если DECREASE - уменьшаем на 1. Так получается счетчик
            return Object.assign({}, state, {
                count: state.count - 1
            })*/
        default:
            // По умолчанию возвращаем предыдущее состояние
            return state
    }
}
export const theme = {
    colors: {
      color_fone: "#f0f0f0",
      color_text: "#323232",
      color_white: "#fff",
      color_logo_green: "#3e8233",
      color_logo_black: "#1a1919",
      color_green: "#71c13b",
      //color_green: "#d0021b",
      //color_green: "#8c8c8c",
      //color_green: "#246da5",
      color_black: "#000",
      color_black1: "#404040",
      color_copirate: "#626262",
      hover_green_button: "#61aa2f",
      color_line: "#dadada",
      color_input: "#979797",
      color_border_select: "#8c8c8c",
      color_chips: "#e2e2e2",
      color_text_button: "#131415",
      color_blue: "#246da5",
      color_red: "#db5c4c",
      color_del: "#d0021b",
      color_vidjet_grey: "#eaeaea",
      color_icon1: "#FF1DFB",
      color_light_text: 'red',
      color_billet_new: "#373643",
    },
    size: {
        small: "320px",
        small1: "360px",
        medium: "768px",
        large: "1024px",
        xl: "1440px",
    },
    margin: {
        margin_small1: "19px",
        margin_small: "16px",
        margin_medium: "32px",
        margin_large: "32px",
        margin_xl: "120px",
    },
    height_header: "64px",
};



/*export const about_ua = "https://privatbank.ua/kredity/avto-v-kredit";
$color_fone: #f0f0f0;
$color_text: #323232;
$color_white: #fff;
$color_logo_green: #3e8233;
$color_logo_black: #1a1919;
$color_green: #71c13b;
$color_black: #000;
$color_black1: #404040;
$color_copirate: #626262;
$hover_green_button: #61aa2f;
$color_line: #dadada;
$color_input: #979797;
$color_border_select: #8c8c8c;
$color_chips: #e2e2e2;
$color_text_button: #131415;
$color_blue: #246da5;
$color_red: #db5c4c;
$color_del: #d0021b;
$color_vidjet_grey: #eaeaea;

$color_icon1: #FF1DFB;
$color_light_text: red;


$small: 320px;
$small1: 360px;
$medium: 768px;
$large: 1024px;
$xl: 1440px;

$margin_small1: 19px;
$margin_small: 16px;
$margin_medium: 32px;
$margin_large: 32px;
$margin_xl: 120px;

$height_header: 64px;*/

import { applyMiddleware, createStore } from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk';
//import initialState from './initialState';


export default function configureStore(preloadedState) {
    //const middlewares = [thunk];

    return createStore(rootReducer, preloadedState, applyMiddleware(thunk));

    /*return createStore(
        rootReducer,
        preloadedState
    )*/
}
import styled from "styled-components";
import {theme} from "../../constants/variables";
export const stylea = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  a {
    text-decoration: none;
  }
.ttteeeesssttt{
color: green;
}
.header_div{
  position: absolute;
  top: 0;
  height: 100px;
  z-index: 0;
  width: 100%;
}
`;
import React, {Fragment} from "react";
import ReactDOM,{ hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Main from "../src/app/components/main/main";
import history from "./app/history";
import Loadable from "react-loadable";
import configureStore from "./redux/configureStore";


// Буквально вытаскиваем initialState из "окна" и заново создаем стор
const state = window.__STATE__;
const store = configureStore(state);


// Оборачиваем приложение созданной темой
Loadable.preloadReady().then(() => {
    hydrate(
        <Provider store={store} >
            <BrowserRouter history={history}>
                <Main/>
            </BrowserRouter>
        </Provider>,
        document.querySelector('#root')
    )
});
/*


ReactDOM.hydrate(
    <Fragment>
        <div>client test!!!</div>
    <BrowserRouter>
        <AppTest/>
    </BrowserRouter>
    </Fragment>,
    document.getElementById('root'),
);*/

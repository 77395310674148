import { access_url } from './config'
import i18next from 'i18next/index'
import https from 'https'
import http from 'http'
const agentHttps = new https.Agent({
    rejectUnauthorized: false,
})
const agentHttp = new http.Agent({
    rejectUnauthorized: false,
})
const fetch = require('node-fetch')

const languageArr = ['ua']
const sortArray = [
    'by_date',
    'by_price_min',
    'by_price_max',
    'by_year',
    'by_run',
]

const MEDIA_SIZE = {
    small: 320,
    small1: 360,
    medium: 768,
    //large: 1023,
    //xl: 1024,
    large: 1024,
    xl: 1440,
}

let price_range = {
    min: 5000,
    max: 2000000,
}

let monthPay_range = {
    min: 1000,
    max: 100000,
}

//let year_min = 2006;

const rolePromin = ['SuperAdmin', 'StaffMember']

export const getParams = (access_token, lang) => {
    const url =
        access_url +
        'getFilters?lang=' +
        lang +
        '&dontShow[0]=hg' +
        '&with_cars=1'
    const header = {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authentication: access_token,
    }

    return fetch(url, {
        method: 'GET',
        agent: process.env.MODE === 'test' ? agentHttp : agentHttps,
        headers: header,
    })
        .then(
            (res) => {
                if (res.status === 200) return res.json()
                else return false
            },
            (error) => {
                return false
            }
        )
        .then((results) => {
            if (!results) {
                return results
            }
            const arr = {
                region: {},
                bodyType: {},
                fuel: {},
                transmission: {},
                typeDrive: {},
                equipment: {},
                type: {},
                sort: {},
                marka: {},
                year: {},
                price: {},
                monthPay: {},
                run: {},
                volume: {},
            }
            for (let [key, value] of Object.entries(arr)) {
                switch (key) {
                    case 'year':
                        arr['year_min'] = results[key][0]
                        break
                    case 'price':
                        break
                    case 'monthPay':
                        break
                    case 'run':
                        break
                    case 'volume':
                        break
                    default:
                        arr[key] = results[key].map((item) => {
                            return {
                                value: item.id,
                                title: item.label,
                                label:
                                    key === 'sort'
                                        ? i18next.t(`${item.id}_title`)
                                        : item.title,
                                title_loc:
                                    key === 'marka' ? item.title_loc : '',
                            }
                        })
                        break
                }
            }
            return arr
        })
}

const url_params = {
    idAuto: 0,
    type: {},
    marka: 0,
    model: 0,
    region: 0,
    bodyType: 0,
    year: { from: 0, to: 0 },
    price: { from: price_range.min, to: price_range.max },
    monthPay: { from: monthPay_range.min, to: monthPay_range.max },
    run: { from: 0, to: 0 },
    volume: { from: 0, to: 0 },
    fuel: {},
    transmission: {},
    typeDrive: {},
    equipment: {},
    sort: 'date',
    region_param: [],
    bodyType_param: [],
    marka_param: [],
    model_pram: [],
    fuel_param: [],
    transmission_param: [],
    typeDrive_param: [],
    equipment_param: [],
    type_param: [],
    sort_param: [],
    page: 0,
    year_min: 1999,
}

const kaskoParam = 0.0699
const avansMinParamIsUse = 600000
const avansMinParamNew = 800000
const avansMaxParam = 100000

const USED_CAR_ID_CATEGORY = 1

export {
    rolePromin,
    sortArray,
    languageArr,
    MEDIA_SIZE,
    url_params,
    price_range,
    monthPay_range,
    kaskoParam,
    avansMinParamIsUse,
    avansMinParamNew,
    avansMaxParam,
    USED_CAR_ID_CATEGORY,
}

import {access_url} from "../constants/config";
import {httpGet, httpPost} from "./access";

export const getCarInfo = (id,access,lang,token = '') => {
//Authorization: Bearer
    const url = access_url+'cars/'+id+'/?lang='+lang;
    return httpGet(url,access,'Authorization','Bearer '+token).then(i=>{
        if (!i) return false;
        if(i.error) return false;
        return {data:i.data}
    });
};

export const getPopularCar = (access,lang,id) => {
    const url = access_url+'getPopular/'+id+'?lang='+lang;
    return httpGet(url,access).then(item=>{
        if (!item) return false;
        let rez = [];
        if(item.data.length > 0) {
            const ll = item.data.length < 5 ? item.data.length : 4;
            for (let i = 0; i < ll; i++) {
                rez.push(item.data[i])
            }
        }
        return {data:rez}
    });
};

export const setEditDelete = (access,id,type,button) => {
    //https://newauto.test.it.loc/api/editCar
    /*{
        "id": 1149,
        "cmd": "edit",//remove
        "buttonValue": "y"
    }*/
    const url = access_url+'editCar';
    const param =  {
        "id": id,
        "cmd": type===1 ? "edit" : "remove",
        "buttonValue": button===1? "y" : "n"
    };
    return httpPost(url,access,'',param).then( item =>{
    });
};
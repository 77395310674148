import {
    avansMaxParam,
    avansMinParamIsUse,
    avansMinParamNew,
    kaskoParam,
    languageArr,
    MEDIA_SIZE,
} from '../constants/params'
import i18next from 'i18next/index'
import { messge_share_url } from '../constants/config'

const fuel_img_electro = '/img/electro.svg'
const fuel_img_gas = '/img/gas.svg'
const transmission_img = '/img/automat.svg'
const mehanik_img = '/img/mehanik.svg'
const bodyType_img = '/img/body-car.svg'
const run_img = '/img/speedometr.svg'

export const convertArrayToObject = (array, val = 0) => {
    const initialValue = {}
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item]: val,
        }
    }, initialValue)
}

export const getLimitResult = () => {
    if (typeof window === 'undefined') {
        return 5
    } else if (window.innerWidth < MEDIA_SIZE.medium) {
        return 5
    } else if (window.innerWidth < MEDIA_SIZE.large) {
        return 6
    } else if (window.innerWidth < MEDIA_SIZE.xl) {
        return 10
    } else if (window.innerWidth >= MEDIA_SIZE.xl) {
        return 15
    }
}

export const getParamValueArr = (params, name, val) => {
    let res = []
    const tmp = params[`${name}_param`].filter((i) => i.value === val)
    if (tmp[0]) {
        res.title = tmp[0].label
        if (name === 'fuel')
            res.src = val === 5 ? fuel_img_electro : fuel_img_gas
        else if (name === 'transmission')
            res.src = val === 1 ? mehanik_img : transmission_img
        else if (name === 'bodyType') res.src = bodyType_img
    } else res = false
    return res
}

export const getFormatInt = (val) => {
    return parseInt(val, 10)
        .toString()
        .replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ')
}

export const getFormatDate = (val) => {
    const d = new Date(val)
    return (
        d.getDate() +
        ' ' +
        i18next.t(`m${d.getMonth()}`) +
        ' ' +
        d.getFullYear() +
        ' ' +
        i18next.t('year_size')
    )
}

export const getParamValue = (name, val) => {
    let res = []
    if (val && val > 0) {
        res.title = i18next.t(`f_${name}`)
        if (name === 'run') res.src = run_img
        res.value = getFormatInt(val)
    } else res = false
    return res
}

export const getParamEquipment = (equipments) => {
    const res = []
    for (let [key, value] of Object.entries(equipments)) {
        if (value === true) {
            res.push(key)
        }
    }
    return res
}

export const fCeil = (val, kol) => {
    return Math.ceil(val / kol) * kol
}

export const fRound10 = (val, kol) => {
    return Math.ceil(val / kol) * kol
}

export const getKasko = (price_avto) => {
    return (price_avto / 12) * kaskoParam //страховка
}

export const getPayMonth = (caroncredit, data, prepay = false) => {
    if (!data || !caroncredit) return false
    const price_avto = data.price_uah //цена авто
    const avans_percent = prepay ? (prepay / price_avto) * 100 : false
    const { month, rate, avans, error } = getSearchCaronCreditVal(
        caroncredit,
        data.id_category,
        data.year,
        avans_percent
    )
    if (error) return false
    const avans_need = prepay
        ? fCeil(prepay, 1000)
        : fCeil((price_avto * avans) / 100.0, 1000) //аванс в грн
    const credit_summ = price_avto - avans_need //тело кредита
    const basis = 365 / 360
    const kasko = getKasko(price_avto) //страховка
    const privProStav = rate / 100 / 12
    const payment =
        (credit_summ *
            basis *
            privProStav *
            Math.pow(1 + basis * privProStav, month)) /
            (Math.pow(1 + basis * privProStav, month) - 1) +
        kasko
    return {
        kasko: fRound10(kasko, 100),
        payment: fCeil(payment, 10),
        prepay: avans_need,
        months: month,
        rate: rate,
        avans: avans,
    }
}

export const getMinAvans = (price, id_category) => {
    const tmp = id_category === 1 ? avansMinParamIsUse : avansMinParamNew
    const m1 = fCeil(price * 0.1, 1000)
    const m2 = fCeil(price - tmp, 1000)
    return Math.max(m1, m2)
}

export const getMaxAvans = (price) => {
    return fCeil(price - avansMaxParam, 1000)
}

export const getSearchCaronCreditVal = (
    caroncredit,
    id_category,
    year,
    avans
) => {
    const arr =
        id_category === 1 || id_category === 7
            ? caroncredit.carType.inuse
            : caroncredit.carType.new
    let month = arr.years.default
    let error = false
    let rate = 0
    if (caroncredit.carType.inuse.years.year) {
        for (let [key, value] of Object.entries(
            caroncredit.carType.inuse.years.year
        )) {
            if (parseInt(key) === year) month = value
        }
    }
    if (!avans) avans = arr.defaultAdvancePercent
    if (arr.creditTerm[month]) {
        for (let [key, value] of Object.entries(
            arr.creditTerm[month].percent
        )) {
            if (
                avans >= parseFloat(value.minAdvancePercent) &&
                avans <= parseFloat(value.maxAdvancePercent)
            ) {
                rate = key
            }
        }
    } else {
        error = true
    }
    return {
        month: month,
        rate: rate,
        avans: arr.defaultAdvancePercent,
        error: error,
    }
}

export const getRegionSklon = (lang, city) => {
    switch (city.value) {
        case 1:
            return lang === languageArr[1]
                ? 'Одесской области'
                : 'Одеській області'
        case 2:
            return lang === languageArr[1]
                ? 'Николаевской области'
                : 'Миколаївській області'
        case 3:
            return lang === languageArr[1]
                ? 'Днепропетровской области '
                : 'Дніпропетровській області'
        case 4:
            return lang === languageArr[1]
                ? 'Киеве и области'
                : 'Києві та області'
        case 5:
            return lang === languageArr[1]
                ? 'Херсонской области'
                : 'Херсонській області'
        case 6:
            return lang === languageArr[1]
                ? 'Донецкой области'
                : 'Донецькій області'
        case 7:
            return lang === languageArr[1]
                ? 'Харьковской области'
                : 'Харківській області'
        case 8:
            return lang === languageArr[1]
                ? 'Луганской области'
                : 'Луганській області'
        case 9:
            return lang === languageArr[1]
                ? 'Запорожской области'
                : 'Запорізькій області'
        case 10:
            return lang === languageArr[1]
                ? 'Сумской области'
                : 'Сумській області'
        case 11:
            return lang === languageArr[1]
                ? 'Винницкой области'
                : 'Вінницькій області'
        case 12:
            return lang === languageArr[1]
                ? 'Ивано-Франковской области'
                : 'Івано-Франківській області'
        case 14:
            return lang === languageArr[1]
                ? 'Львовской области'
                : 'Львівській області'
        case 15:
            return lang === languageArr[1]
                ? 'Черновицкой области'
                : 'Чернівецькій області'
        case 16:
            return lang === languageArr[1]
                ? 'Кировоградской области'
                : 'Кіровоградській області'
        case 17:
            return lang === languageArr[1]
                ? 'Полтавской области'
                : 'Полтавській області'
        case 18:
            return lang === languageArr[1]
                ? 'Черкасской области'
                : 'Черкаській області'
        case 19:
            return lang === languageArr[1]
                ? 'Черниговской области'
                : 'Чернігівській області'
        case 20:
            return lang === languageArr[1]
                ? 'Житомирской области'
                : 'Житомирській області'
        case 21:
            return lang === languageArr[1]
                ? 'Хмельницкой области'
                : 'Хмельницькій області'
        case 22:
            return lang === languageArr[1]
                ? 'Тернопольской области'
                : 'Тернопільській області'
        case 23:
            return lang === languageArr[1]
                ? 'Ровенской области'
                : 'Рівненській області'
        case 24:
            return lang === languageArr[1]
                ? 'Волынской области'
                : 'Волинській області'
        case 25:
            return lang === languageArr[1]
                ? 'Закарпатской области'
                : 'Закарпатській області'
        default:
            return lang === languageArr[1] ? 'Украине' : 'Україні'
    }
}

export const getCitySklon = (lang, city) => {
    switch (city) {
        case 1:
            //return lang === languageArr[1] ?'Одесской области':'Одеській області';
            return lang === languageArr[1] ? 'Одессе' : 'Одесі'
        case 2:
            //return lang === languageArr[1] ?'Николаевской области':'Миколаївській області';
            return lang === languageArr[1] ? 'Николаеве' : 'Миколаєві'
        case 3:
            //return lang === languageArr[1] ?'Днепропетровской области ':'Дніпропетровській області';
            return lang === languageArr[1] ? 'Днепре' : 'Дніпрі'
        case 4:
            //return lang === languageArr[1] ?'Киеве и области':'Києві та області';
            return lang === languageArr[1] ? 'Киеве' : 'Києві'
        case 5:
            //return lang === languageArr[1] ?'Херсонской области':'Херсонській області';
            return lang === languageArr[1] ? 'Херсоне' : 'Херсоні'
        case 6:
            return lang === languageArr[1]
                ? 'Донецкой области'
                : 'Донецькій області'
        //return lang === languageArr[1] ?'Донецке':'Донецьку';
        case 7:
            //return lang === languageArr[1] ?'Харьковской области':'Харківській області';
            return lang === languageArr[1] ? 'Харькове' : 'Харкові'
        case 8:
            return lang === languageArr[1]
                ? 'Луганской области'
                : 'Луганській області'
        //return lang === languageArr[1] ?'Луганске':'Луганську';
        case 9:
            //return lang === languageArr[1] ?'Запорожской области':'Запорізькій області';
            return lang === languageArr[1] ? 'Запорожье' : 'Запоріжжі'
        case 10:
            //return lang === languageArr[1] ?'Сумской области':'Сумській області';
            return lang === languageArr[1] ? 'Сумах' : 'Сумах'
        case 11:
            //return lang === languageArr[1] ?'Винницкой области':'Вінницькій області';
            return lang === languageArr[1] ? 'Виннице' : 'Вінниці'
        case 12:
            //return lang === languageArr[1] ?'Ивано-Франковской области':'Івано-Франківській області';
            return lang === languageArr[1]
                ? 'Ивано-Франковске'
                : 'Івано-Франківську'
        case 14:
            //return lang === languageArr[1] ?'Львовской области':'Львівській області';
            return lang === languageArr[1] ? 'Львове' : 'Львові'
        case 15:
            //return lang === languageArr[1] ?'Черновицкой области':'Чернівецькій області';
            return lang === languageArr[1] ? 'Черновцах' : 'Чернівцях'
        case 16:
            //return lang === languageArr[1] ?'Кировоградской области':'Кіровоградській області';
            return lang === languageArr[1] ? 'Кировограде' : 'Кіровограді'
        case 17:
            //return lang === languageArr[1] ?'Полтавской области':'Полтавській області';
            return lang === languageArr[1] ? 'Полтаве' : 'Полтаві'
        case 18:
            //return lang === languageArr[1] ?'Черкасской области':'Черкаській області';
            return lang === languageArr[1] ? 'Черкассах' : 'Черкасах'
        case 19:
            //return lang === languageArr[1] ?'Черниговской области':'Чернігівській області';
            return lang === languageArr[1] ? 'Чернигове' : 'Чернігові'
        case 20:
            //return lang === languageArr[1] ?'Житомирской области':'Житомирській області';
            return lang === languageArr[1] ? 'Житомире' : 'Житомиру'
        case 21:
            //return lang === languageArr[1] ?'Хмельницкой области':'Хмельницькій області';
            return lang === languageArr[1] ? 'Хмельницке' : 'Хмельницькому'
        case 22:
            //return lang === languageArr[1] ?'Тернопольской области':'Тернопільській області';
            return lang === languageArr[1] ? 'Тернополе' : 'Тернополі'
        case 23:
            //return lang === languageArr[1] ?'Ровенской области':'Рівненській області';
            return lang === languageArr[1] ? 'Ровном' : 'Рівному'
        case 24:
            //return lang === languageArr[1] ?'Волынской области':'Волинській області';
            return lang === languageArr[1] ? 'Луцке' : 'Луцьку'
        case 25:
            //return lang === languageArr[1] ?'Закарпатской области':'Закарпатській області';
            return lang === languageArr[1] ? 'Закарпатье' : 'Закарпатті'
        default:
            return lang === languageArr[1] ? 'Украине' : 'Україні'
    }
}

const rrq = {
    region_param: [
        { value: 0, title: undefined, label: 'Все регионы' },
        { value: 11, title: 'Vinnytska', label: 'Винницкая' },
        { value: 24, title: 'Volynska', label: 'Волынская' },
        { value: 3, title: 'Dnipropetrovska', label: 'Днепропетровская' },
    ],
    marka_param: [
        { value: 0, title: undefined, label: 'Марка автомобиля' },
        { value: 2, title: 'Acura', label: 'Acura' },
        { value: 9, title: 'Audi', label: 'Audi' },
        { value: 20, title: 'BMW', label: 'BMW' },
    ],
    type: {
        'in-use': 1,
        new: 1,
        // bankcar: 0
    },
    marka: 20,
    region: 3,
}

export const getHelmetData = (lang, params, info = false) => {
    //console.log(params);
    //console.log(info);
    let title = ''
    let description = ''
    let image = ''
    let url = ''
    let link = ''
    let title_info
    let description_info

    let t_in_use = params.type['in-use'] === 1 ? 1 : 0
    let t_new = params.type['new'] === 1 ? 1 : 0
    let t_bank_car = params.type['bankcar'] === 1 ? 1 : 0
    let model_ru = ''
    let model_en = ''
    if (params.marka !== 0) {
        params.marka_param.map((item) => {
            if (item.value === params.marka) {
                model_ru = item.title_loc
                model_en = item.title
            }
        })
    }
    let region = ''
    if (params.region !== 0) {
        region = getCitySklon(lang, params.region)
    }

    let tmp1 = []
    let tmp2 = []
    let tmp3 = []
    if (t_new) {
        tmp1.push(i18next.t('discription3'))
        tmp2.push(i18next.t('title5'))
        tmp3.push(i18next.t('text_descr1'))
    }
    if (t_in_use) {
        tmp1.push(i18next.t('discription2'))
        tmp2.push(i18next.t('title6'))
        tmp3.push(i18next.t('text_descr2'))
    }
    if (t_bank_car) {
        tmp1.push(i18next.t('discription3_1'))
        tmp2.push(i18next.t('title6_'))
        tmp3.push(i18next.t('text_descr3'))
    }

    title =
        i18next.t('title1') +
        (params.marka === 0 ? i18next.t('title7') : model_ru + ' ') +
        tmp2.join(' ' + i18next.t('discription10') + ' ') +
        ' ' +
        i18next.t('title3') +
        (params.region === 0 ? i18next.t('landing_title_country') : region) +
        i18next.t('title4') +
        tmp2.join(' ' + i18next.t('discription10') + ' ') +
        ' ' +
        i18next.t('title7') +
        (params.marka === 0 ? '' : model_en) +
        (t_new ? '' : t_in_use || t_bank_car ? i18next.t('discription9') : '')

    description =
        i18next.t('discription1') +
        tmp1.join(' ' + i18next.t('discription10') + ' ') +
        ' ' +
        i18next.t('discription11') +
        (params.marka === 0 ? '' : model_ru + ' ') +
        i18next.t('discription4') +
        (params.region === 0 ? i18next.t('landing_title_country') : region) +
        i18next.t('discription6') +
        tmp3.join(', ') +
        ' ' +
        i18next.t('discription8') +
        i18next.t('discription12') +
        (params.marka === 0 ? '' : model_en) +
        (t_new ? '' : t_in_use || t_bank_car ? i18next.t('discription9') : '') +
        i18next.t('discription13')

    if (info) {
        link =
            messge_share_url +
            (lang === 'ua' ? '' : lang + '/') +
            'car/' +
            info.id
        url = link
        image = info.image.jpg[1].img
        title_info =
            i18next.t('title_img1') +
            (info.id_category === 1
                ? i18next.t('is_use')
                : info.id_category === 2
                ? i18next.t('new')
                : i18next.t('bankcar')) +
            ' ' +
            info.title +
            ' ' +
            info.year +
            ' ' +
            i18next.t('year_size') +
            ' ' +
            (info.id_category === 1
                ? i18next.t('title_img4') +
                  info.mileage +
                  ' ' +
                  i18next.t('run_title') +
                  ' '
                : '') +
            i18next.t('title_img5') +
            info.price_uah +
            ' ' +
            i18next.t('grn') +
            ' ' +
            info.city

        description_info = title_info
    } else {
        link = messge_share_url + (lang === 'ua' ? '' : lang + '/')
        let param_url = ['cars']
        let count_type = 0
        for (let key in params.type) {
            if (params.type[key] === 1) {
                param_url.push(key)
                count_type++
            }
        }
        if (params.marka !== 0) {
            param_url.push(model_en)
        }
        if (params.region !== 0) {
            const region = params.region_param.find((item) => {
                return item.value === params.region
            })
            param_url.push('city/' + region.title)
        }
        const flag = param_url.length === 4 && count_type === 3 ? false : true //выбраны только все категории
        if (flag && param_url.length > 1) {
            link += param_url.join('/')
        }
    }
    return {
        title: title,
        description: description,
        image: image,
        url: url,
        link: link,
        title_info: title_info,
        description_info: description_info,
    }
}

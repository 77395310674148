import React, { Component, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
//import {useParams} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import Loadable from 'react-loadable'
import Loading from '../../../Loading'
//import Loading from '&/Loading'
import { bindActionCreators } from 'redux'
import i18next from 'i18next/index'
import cookie from 'react-cookies'
import { connect } from 'react-redux'

//import * as Actions from '&/redux/actions'
import * as Actions from '../../../redux/actions'
import { setAccessHeader } from '../../library/access'
import { getIp, getLogout, getRole, getUserParam } from '../../library/users'
import { setLanguage } from '../../library/lib'
import * as filter from '../../library/filter_param'
import { getCaronCredit } from '../../library/caroncredit'
import { getUrlParam } from '../../library/url'
import {
    getParams,
    languageArr,
    monthPay_range,
    price_range,
} from '../../constants/params'

import { stylea } from './main.style'
import { getCarInfo } from '../../library/infoGet'
import {
    getFormatInt,
    getParamEquipment,
    getParamValue,
    getParamValueArr,
} from '../../library/helper'
///Users/tanya/project/php/auto/node_modules/infinite-react-carousel/lib/carousel/slider.js - line 32

export const AppContext = React.createContext({})

//TODO:: написать тесты
//TODO:: подключить typeScript

//TODO:: передавать phone в апи https://auto.test.it.loc/api/cars/1193/?lang=ua
//TODO:: передавать 3: {id: "price_asc", title: "сначала дешевле", label: "price"} 4: {id: "price_desc", title: "сначала дороже", label: "price"} в апи https://auto.test.it.loc/api/getSort?lang=ru
//TODO:: категория банковские авто

const AsyncHeader = Loadable({
    loader: () => import('./../header/header'),
    loading: Loading,
    delay: 300,
})
const AsyncFooter = Loadable({
    loader: () => import('./../footer/footer'),
    loading: Loading,
    delay: 300,
})
const AsyncLanding = Loadable({
    loader: () => import('./../landing/landing'),
    loading: Loading,
    delay: 300,
})
const AsyncInfo = Loadable({
    loader: () => import('./../info/info'),
    loading: Loading,
    delay: 300,
})
const AsyncCookies = Loadable({
    loader: () => import('./../cookies/cookies'),
    loading: Loading,
    delay: 300,
})
const AsyncJakor = Loadable({
    loader: () => import('./../jakor/jakor'),
    loading: Loading,
    delay: 300,
})
const AsyncError = Loadable({
    loader: () => import('./../error/error'),
    loading: Loading,
    delay: 300,
})
const AsyncWindowForm = Loadable({
    loader: () => import('./../windowForm/windowForm'),
    loading: Loading,
    delay: 300,
})
const MainStyle = stylea

class Main extends Component {
    constructor(props) {
        super(props)
        const lang = this.props.lang
        const acess_token = setAccessHeader()
        //let userId = false;
        const jwtToken =
            process.env.MODE === 'test'
                ? 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXV0by50ZXN0Lml0LmxvY1wvYXV0aFwvZWNhTG9naW4iLCJpYXQiOjE2MDM4ODkwODcsImV4cCI6MTYwMzg5MjY4NywibmJmIjoxNjAzODg5MDg3LCJqdGkiOiJhNUxpZkM4djJVSUVrQlZtIiwic3ViIjoyMiwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.7ymfXnz6kcsp49-a_Ac8AksUMnYfs7xaewwpjr4LXPo'
                : cookie.load('jwt-token')

        this.state = {
            lang: lang,
            userId: false,
            access: acess_token,
            result: [],
            params: this.props.params,
            hasError: this.props.params ? false : true,
            count_inUs: 0,
            count_new: 0,
            count_bank: 0,
            count: 0,
            caroncredit: false,
            old_param: false,
            local: false,
            jwtToken: jwtToken,
            buyShow: false,
            sellShow: false,
            opp_menu: false,
            jakorShow: false,
            count_rez: 0,
            load: false,
            ssrParams: this.props.paramsCar,
            hist_now: '',
            caroncredit_load: false,
            saveY: 0,
            countShow: 0,
            countLoadNext: 0,
        }
        setLanguage(lang)
        getIp().then((item) => {
            this.setState({ local: item })
        })
        getUserParam(jwtToken, lang).then((item) => {
            if (item) {
                if (this.props.paramsCar && getRole(item.role.name)) {
                    getCarInfo(
                        this.props.paramsCar.id,
                        acess_token,
                        lang,
                        jwtToken
                    ).then((itemCar) => {
                        this.setState({
                            userId: item,
                            ssrParams: item ? itemCar.data : false,
                        })
                    })
                } else {
                    this.setState({
                        userId: item,
                    })
                }
            }
        })
        getCaronCredit().then((item) => {
            this.setState({
                caroncredit: item,
                caroncredit_load: true,
            })
        })
    }

    lang_change = false
    lastY = 0

    jakor_f = (event) => {
        event.stopPropagation()
        event.preventDefault()
        window.scrollTo(0, 0)
        this.setState({ jakorShow: false })
    }
    setBuySellShow = () => {
        this.setState({
            buyShow: false,
            sellShow: false,
        })
    }
    setBuyShow = () => {
        window.scrollTo(0, 0)
        this.setState({
            buyShow: !this.state.buyShow,
            sellShow: false,
        })
    }
    setOppMenu = (val) => {
        this.setState({
            opp_menu: val,
        })
    }
    setSellShow = () => {
        window.scrollTo(0, 0)
        this.setState({
            buyShow: false,
            sellShow: !this.state.sellShow,
        })
    }
    setLanguage = (language, jwtToken) => {
        i18next.init({
            lng: language,
            resources: require(`../../locales/${language}.json`),
        })
        if (jwtToken) {
            getUserParam(jwtToken, language).then((item) => {
                this.setState({ userId: item })
            })
        }
    }
    clickBack1 = () => {
        let { old_param } = this.state
        if (old_param !== false) {
            this.setState({
                params: old_param,
                ssrParams: false,
            })
        }
    }
    saveCountShow = (val) => {
        this.setState({
            countShow: val,
        })
    }
    clickLink = (e, val, history, flag = false) => {
        if (e.ctrlKey || e.metaKey) return false
        let { params } = this.state
        const old_param = Object.assign({}, params)
        let newState = filter.setInitialParams(params, val)
        newState.page = 0
        //console.log('link='+window.scrollY);
        let newSt = {
            params: newState,
            old_param: old_param,
            ssrParams: false,
        }
        if (flag) {
            newSt.saveY = window.scrollY
        }
        if (val === '' || val === 'in-use' || val === 'new') {
            newSt.result = []
            newSt.saveY = 0
            newSt.countShow = 0
        }
        this.setState(newSt)
        //this.setHist(history,newState,val);
        window.scrollTo(0, 0)
    }
    logoutF = () => {
        getLogout(this.state.jwtToken)
        cookie.remove('jwt-token')
        this.setState({
            userId: false,
            jwtToken: false,
        })
    }
    languageChange = (customHistory) => {
        const { lang, params, access, jwtToken } = this.state
        const id = Object.keys(languageArr).find(
            (key) => languageArr[key] === lang
        )
        const value = languageArr[id ^ 1]

        if (this.lang_change === false) {
            this.setLanguage(value, jwtToken)
            this.lang_change = true
            getParams(access, value).then(async (item) => {
                let newState = Object.assign({}, params)
                newState.region_param = item.region
                newState.bodyType_param = item.bodyType
                newState.marka_param = item.marka
                newState.fuel_param = item.fuel
                newState.transmission_param = item.transmission
                newState.typeDrive_param = item.typeDrive
                newState.equipment_param = item.equipment
                newState.type_param = item.type
                newState.sort_param = item.sort
                newState.model_pram = await filter.getElementModel(
                    params.marka,
                    access,
                    lang
                )
                newState.year_min = item.year_min

                this.setState({
                    params: newState,
                    lang: value,
                    result: [],
                })
                const history_param = getUrlParam(
                    customHistory.location.pathname
                )
                if (value === languageArr[0]) {
                    history_param.shift()
                    customHistory.push(`/${history_param.join('/')}`)
                } else {
                    customHistory.push(`/${value}/${history_param.join('/')}`)
                }
                this.lang_change = false
            })
        }
    }
    setParams = (history, type, val, flag) => {
        let { lang, params, access } = this.state
        let newState = Object.assign({}, params)

        const new_hist = []
        if (lang !== languageArr[0]) {
            new_hist.push(lang)
        }
        new_hist.push('cars')

        if (flag === 1) {
            const new_val = newState[type][val] ^ 1
            newState[type] = { ...newState[type], [val]: new_val }
        } else if (flag === 2) {
            if (type === 'marka') {
                newState['model'] = 0
                if (val !== 0) {
                    filter.getElementModel(val, access, lang).then((items) => {
                        let newState1 = Object.assign({}, params)
                        newState1['model_pram'] = items
                        newState1['marka'] = val
                        newState1['model'] = 0
                        this.setState({
                            //...this.state.params,
                            params: newState1,
                        })
                    })
                }
            } else if (
                type === 'type' &&
                val === 'new' &&
                params.sort === 'mileage'
            ) {
                let newState1 = Object.assign({}, params)
                newState1['sort'] = 'date'
                this.setState({
                    params: newState1,
                })
            }
            newState[type] = val
        } else if (flag === 3) {
            if (val === '' || val === 0) {
                if (type === 'price') {
                    newState[type] = {
                        ...newState[type],
                        from: price_range.min,
                    }
                } else if (type === 'monthPay') {
                    newState[type] = {
                        ...newState[type],
                        from: monthPay_range.min,
                    }
                } else {
                    newState[type] = { ...newState[type], from: 0 }
                }
            } else {
                newState[type] = { ...newState[type], from: val }
            }
        } else if (flag === 4) {
            if (val === '' || val === 0) {
                if (type === 'price') {
                    newState[type] = { ...newState[type], to: price_range.max }
                } else if (type === 'monthPay') {
                    newState[type] = {
                        ...newState[type],
                        to: monthPay_range.max,
                    }
                } else {
                    newState[type] = { ...newState[type], to: 0 }
                }
            } else {
                newState[type] = { ...newState[type], to: val }
            }
        } else if (flag === 5) {
            newState[type] = { ...newState[type], from: val[0] }
            newState[type] = { ...newState[type], to: val[1] }
        } else {
            newState = filter.setInitialParams(params, '')
        }
        newState.page = 0
        this.setState({
            params: newState,
            result: [],
            ssrParams: false,
            saveY: 0,
            countShow: 0,
        })
        this.setHist(history, newState)
    }
    searchNextResult = () => {
        let { params, result, count, load, countLoadNext } = this.state
        if (result.length < count && load === false) {
            if (countLoadNext > 0) {
                let newState = Object.assign({}, params)
                newState['page'] = params.page + 1
                this.setState({
                    //page: page+1,
                    params: newState,
                    load: true,
                    countLoadNext: countLoadNext + 1,
                })
            } else {
                this.setState({
                    countLoadNext: countLoadNext + 1,
                })
            }
        }
    }
    setCount = (col1, col2, col3, rez) => {
        let { params } = this.state
        //console.log(params.type)
        const col1_ = col1 !== false ? col1 : this.state.count_inUs
        const col2_ = col2 !== false ? col2 : this.state.count_new
        // const col3_ = col3 !== false ? col3 : this.state.count_bank;
        let rez_ = 0
        let fl = false
        if (params.type['in-use'] === 1) {
            rez_ += col1_
            fl = true
        }
        if (params.type['new'] === 1) {
            rez_ += col2_
            fl = true
        }
        // if(params.type['bankcar'] === 1) {
        //     rez_ += col3_;
        //     fl = true;
        // }
        if (!fl) {
            rez_ = col1_ + col2_
        }
        this.setState({
            load: false,
            count_inUs: col1_,
            count_new: col2_,
            // count_bank: col3_,
            count: rez_,
            result: rez,
            count_rez: rez.length,
        })
    }
    setError = () => {
        this.setState({ hasError: true })
    }
    setLoad = (val) => {
        this.setState({ load: val })
    }
    setHist = (customHistory, params, val = '') => {
        //http://localhost:3000/ru/cars/is_use/toyota/model1/city/Dnepr/year_from/2001/year_to/2004/price_from/10000/price_to/900000/month_pay_from/5000/month_pay_to/7000/run_from/10/run_to/50/petrol/diesel/electro/hybrid/volume_from/1.1/volume_to/4.2/machine/mechanics/tiptronic/cabriolet/rear/front/full/alarm/power_steering/toning/car_radio/central_locking/leather_interior/electric_mirrors/power_windows/alloy_wheels/heated_seats/cruise_control/sort/by_date
        let { lang } = this.state
        const new_hist = []
        if (params) {
            //lang
            if (val.match(/car\//)) {
                new_hist.push(val)
            } else {
                if (lang !== languageArr[0]) {
                    new_hist.push(lang)
                }

                new_hist.push('cars')

                //type
                for (let [key, value] of Object.entries(params.type)) {
                    if (value !== 0) new_hist.push(key)
                }
                //marka
                if (params.marka !== 0) {
                    const marka = params.marka_param.find((item) => {
                        return item.value === params.marka
                    })
                    new_hist.push(marka.title)
                }
                //model
                if (params.model !== 0) {
                    const model = params.model_pram.find((item) => {
                        return item.value === params.model
                    })
                    new_hist.push(model.title)
                }
                //city/Dnepr/
                if (params.region !== 0) {
                    const region = params.region_param.find((item) => {
                        return item.value === params.region
                    })
                    new_hist.push('city')
                    new_hist.push(region.title)
                }
                //year_from year_to
                if (params.year.from !== 0) {
                    new_hist.push('year_from')
                    new_hist.push(params.year.from)
                }
                if (params.year.to !== 0) {
                    new_hist.push('year_to')
                    new_hist.push(params.year.to)
                }
                //price_from price_to
                if (params.price.from > price_range.min) {
                    new_hist.push('price_from')
                    new_hist.push(params.price.from)
                }
                if (params.price.to < price_range.max) {
                    new_hist.push('price_to')
                    new_hist.push(params.price.to)
                }
                //month_pay_from
                if (params.monthPay.from > monthPay_range.min) {
                    new_hist.push('month_pay_from')
                    new_hist.push(params.monthPay.from)
                }
                if (params.monthPay.to < monthPay_range.max) {
                    new_hist.push('month_pay_to')
                    new_hist.push(params.monthPay.to)
                }
                //run_from
                if (params.run.from !== 0) {
                    new_hist.push('run_from')
                    new_hist.push(params.run.from)
                }
                if (params.run.to !== 0) {
                    new_hist.push('run_to')
                    new_hist.push(params.run.to)
                }
                //fuel
                for (let [key, value] of Object.entries(params.fuel)) {
                    if (value !== 0) new_hist.push(key)
                }
                //volume_from
                if (params.volume.from !== 0) {
                    new_hist.push('volume_from')
                    new_hist.push(params.volume.from)
                }
                if (params.volume.to !== 0) {
                    new_hist.push('volume_to')
                    new_hist.push(params.volume.to)
                }
                //transmission
                for (let [key, value] of Object.entries(params.transmission)) {
                    if (value !== 0) new_hist.push(key)
                }
                //bodyType
                if (params.bodyType !== 0) {
                    const bodyType = params.bodyType_param.find((item) => {
                        return item.value === params.bodyType
                    })
                    new_hist.push(bodyType.title)
                }
                //typeDrive
                for (let [key, value] of Object.entries(params.typeDrive)) {
                    if (value !== 0) new_hist.push(key)
                }
                //equipment
                for (let [key, value] of Object.entries(params.equipment)) {
                    if (value !== 0) new_hist.push(key)
                }
                //sort
                if (params.sort !== 'date') {
                    new_hist.push('sort')
                    new_hist.push(params.sort)
                }
                if (params.idAuto !== 0) {
                    new_hist.push('id')
                    new_hist.push(params.idAuto)
                }
            }
            if (params.utm_source) {
                new_hist.push('utm_source')
                new_hist.push(params.utm_source)
            }
            customHistory.push(`/${new_hist.join('/')}`)
            //console.log('set',customHistory.action,customHistory.location)
        }
    }

    onScroll = (event) => {
        const { jakorShow } = this.state
        if (event.nativeEvent.wheelDelta < 0) {
            if (jakorShow === true) this.setState({ jakorShow: false })
        } else {
            if (jakorShow === false) this.setState({ jakorShow: true })
        }
    }
    onTouchStart = (event) => {
        this.lastY = event.nativeEvent.touches[0].clientY
        //event.preventDefault();
        //event.stopPropagation();
        //event.preventDefault();
    }
    onTouchEnd = (event) => {
        const { jakorShow } = this.state
        let currentY = event.changedTouches[0].clientY
        if (Math.abs(currentY - this.lastY) >= 50) {
            if (currentY < this.lastY) {
                if (jakorShow === true) this.setState({ jakorShow: false })
            } else {
                if (jakorShow === false) this.setState({ jakorShow: true })
            }
            this.lastY = currentY
        }
        //event.stopPropagation();
        //event.preventDefault();
    }
    onTouchMove = (event) => {
        const { jakorShow } = this.state
        let currentY = event.nativeEvent.touches[0].clientY
        if (Math.abs(currentY - this.lastY) >= 50) {
            if (currentY > this.lastY) {
                if (jakorShow === true) this.setState({ jakorShow: false })
            } else {
                if (jakorShow === false) this.setState({ jakorShow: true })
            }
            this.lastY = currentY
        }
        event.preventDefault()
    }

    render() {
        const {
            countShow,
            saveY,
            caroncredit_load,
            ssrParams,
            count_rez,
            jakorShow,
            opp_menu,
            buyShow,
            sellShow,
            lang,
            local,
            jwtToken,
            userId,
            params,
            access,
            result,
            hasError,
            count_bank,
            count_inUs,
            count_new,
            count,
            caroncredit,
            load,
        } = this.state
        return (
            <Fragment>
                <div />
                <MainStyle
                    onWheel={this.onScroll}
                    onTouchMove={this.onTouchMove}
                    onTouchStart={this.onTouchStart}
                    onTouchEnd={this.onTouchEnd}
                >
                    {hasError && <AsyncError styleName={'error'} />}
                    {!hasError && (
                        <Switch>
                            <Route
                                exact
                                path={'/:lang*/car/:parameter*'}
                                render={({ history }) => {
                                    return (
                                        <AppContext.Provider
                                            value={{
                                                lang: lang,
                                                local: local,
                                                clickLink: this.clickLink,
                                                customHistory: history,
                                                access: access,
                                                setError: this.setError,
                                                params: params,
                                                caroncredit: caroncredit,
                                                userId: userId,
                                                clickBack1: this.clickBack1,
                                                buyShow: buyShow,
                                                sellShow: sellShow,
                                                setBuySellShow:
                                                    this.setBuySellShow,
                                                ssrParams: ssrParams,
                                                setBuyShow: this.setBuyShow,
                                                setSellShow: this.setSellShow,
                                                jakor_f: this.jakor_f,
                                                languageChange:
                                                    this.languageChange,
                                                result: result,
                                                jwtToken: jwtToken,
                                                logoutF: this.logoutF,
                                                opp_menu: opp_menu,
                                                caroncredit_load:
                                                    caroncredit_load,
                                            }}
                                        >
                                            <AsyncHeader />
                                            {(buyShow || sellShow) && (
                                                <AsyncWindowForm
                                                    fBuy={buyShow}
                                                    fSell={sellShow}
                                                />
                                            )}
                                            <AsyncInfo />
                                            {<AsyncFooter /> || <Skeleton />}
                                            <AsyncCookies />
                                            {jakorShow && <AsyncJakor />}
                                        </AppContext.Provider>
                                    )
                                }}
                            />
                            <Route
                                exact
                                path={['/', '/:lang*/cars/:param*', '/:param*']}
                                render={({ history }) => {
                                    return (
                                        <AppContext.Provider
                                            value={{
                                                setError: this.setError,
                                                params: params,
                                                setCount: this.setCount,
                                                result: result,
                                                lang: lang,
                                                access: access,
                                                buyShow: buyShow,
                                                sellShow: sellShow,
                                                setOppMenu: this.setOppMenu,
                                                searchNextResult:
                                                    this.searchNextResult,
                                                count: count,
                                                load: load,
                                                setLoad: this.setLoad,
                                                setParams: this.setParams,
                                                local: local,
                                                userId: userId,
                                                count_bank: count_bank,
                                                count_inUs: count_inUs,
                                                count_new: count_new,
                                                caroncredit: caroncredit,
                                                clickLink: this.clickLink,
                                                count_rez: count_rez,
                                                customHistory: history,
                                                setBuyShow: this.setBuyShow,
                                                setSellShow: this.setSellShow,
                                                jakor_f: this.jakor_f,
                                                languageChange:
                                                    this.languageChange,
                                                jwtToken: jwtToken,
                                                logoutF: this.logoutF,
                                                opp_menu: opp_menu,
                                                saveY: saveY,
                                                saveCountShow:
                                                    this.saveCountShow,
                                                countShow: countShow,
                                            }}
                                        >
                                            <AsyncHeader />
                                            {(buyShow || sellShow) && (
                                                <AsyncWindowForm
                                                    fBuy={buyShow}
                                                    fSell={sellShow}
                                                />
                                            )}
                                            <AsyncLanding />
                                            {<AsyncFooter /> || <Skeleton />}
                                            <AsyncCookies />
                                            {jakorShow && <AsyncJakor />}
                                        </AppContext.Provider>
                                    )
                                }}
                            />
                        </Switch>
                    )}
                </MainStyle>
            </Fragment>
        )
    }
}

// Добавляем в props счетчик
const mapStateToProps = (state) => ({
    lang: state.lang,
    params: state.params,
    paramsCar: state.paramsCar,
})
// Добавляем actions к this.props
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(Actions, dispatch),
    //fetchProducts: bindActionCreators(fetchProducts, dispatch)
})

// Используем react-redux connect для подключения к стору
export default connect(mapStateToProps, mapDispatchToProps)(Main)

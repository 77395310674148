import {caronCredit_url} from "../constants/config";
import {httpPost} from "./access";

export const getCaronCredit = () => {
    const url = caronCredit_url + 'index';
    const param =  { "widget": "autopbua" };
    return httpPost(url,'','',param).then( item =>{
        if (!item || item.error || !item.autopbua) return false;
        return item.autopbua;
    });
};

export const sendPhone = (phone) => {
    //+380922174593
    //+380509909123
    //https://caroncredit.test.it.loc/api/otp/create
    // Запрос { "phone": "+380922174593" // здесь нужен свой финансовый телефон т.к. отп прийдет в Приват24 мобильный }
    // Ответ { "status": true, "phone": "+380922174593" }
    const url = caronCredit_url + 'otp/create';
    const param =  { "phone": phone };
    return httpPost(url,'','',param).then( item =>{
        if(!item || item.error === 401) return false;
        else if(item.error === 429) return 429;//привышен лимит
        return (item.status === true) ? true :false;
    });
};

export const sendOtp = (phone,otp,paramsAll,userId) => {
    //https://caroncredit.test.it.loc/api/login
    //запрос { "auth_type": "otp", "phone": "+380922174593", "otpcode": "199327" // сюда подставляем свой код который пришел в приват24 }
    //ответ { "status": true, "idEKB": "7117572", "validPhone": true, "token": "eyJ0e"}
    const url = caronCredit_url + 'login';
    //"attracted_user":(userId && userId.login)? userId.login : (utm !== false) ? utm : 'autopbua',
    const param =  {
        "auth_type": "otp",
        "phone": phone,
        "otpcode": otp,
        "attracted_user":(userId && userId.login)? userId.login : (paramsAll.utm_source) ? paramsAll.utm_source : 'autopbua',
    };
    return httpPost(url,'','',param).then( item =>{
        if(item.error === 401) return 401;//не правильный отп
        else if(item.error === 429) return 429;//привышен лимит отп
        else if (item.status === true) return item;//положительный ответ
        else return 500;//ошибка сервиса
    });
};

export const getDealByIdEkb = (idEKB,token) => {
    //https://caroncredit.test.it.loc/api/getDealByIdEkb
    //{"idEKB": "3603220","ignoreDate": true}
    //idEKB = 3603220;
    const url = caronCredit_url + 'secured/getDealByIdEkb';
    const param =  {"idEKB": idEKB,"ignoreDate": true};
    return httpPost(url,'','Bearer '+token,param).then(item => {
        if(item && item.success === false) return false;
        else if(item && item.dealid) return item;
        else return 500;
    });
};


export const getDetail = (idEKB,token) => {
    //{ "idEKB": "7117572" }
    const url = caronCredit_url + 'secured/getEkbDataById';
    const param = { "idEKB": idEKB };
    return httpPost(url,'','Bearer '+token,param).then(item => {
        if(item.error) return false;
        else return item;
    });
};

export const putAnketa = (params,credit,autoInfo,token,userId,utm) => {
    //https://caroncredit.test.it.loc/api/putRequest
    const url = caronCredit_url + 'secured/putRequest';
    const param = { "incomeOfficial": params.incomeOfficial,
                    "incomeNotOfficial": params.incomeNotOfficial,
                    "lengthOfWork": params.lengthOfWork,
                    "socialStatus": params.socialStatus,
                    "familyStatus": params.familyStatus,
                    "widget": "autopbua",
                    "prepayment": credit.credit.prepay,
                    "price": autoInfo.price_uah,
                    "creditType": autoInfo.id_category === 1 ? "inuse" : "new",
                    "creditTerm": credit.credit.months,
                    "idEKB": credit.idEKB,
                    "attracted_user":(userId && userId.login)? userId.login : (utm !== false) ? utm : 'autopbua',
                    "src":"planetavto"
    };
    return httpPost(url,'','Bearer '+token,param).then(item => {
        if(item.error) return false;
        else return item;
    });
};

export const getOtcDataByDealid = (dealid,token) => {
    const url = caronCredit_url + 'secured/getOtcDataByDealid';
    const param = {"dealid": dealid};
    return httpPost(url,'','Bearer '+token,param).then(item => {
        if(item.error) return false;
        else return item;
    });
};

export const getDealByExtRef = (idEkb,extRef,token) => {
    const url = caronCredit_url + 'secured/getDealByExtRef';
    const param = {
        "idEKB": idEkb,
        "extRef": extRef
    };
    return httpPost(url,'','Bearer '+token,param).then(item => {
        if(item.error) return false;
        else if(item.status === true && item['@attributes'].dealid) return item['@attributes'].dealid;
        else return false;
    });
};

export const sendEmail = (email,dealid,token) => {
    const url = caronCredit_url + 'secured/sendEmail';
    const param = {
        "dealid": dealid,
        "email": email
    };
    return httpPost(url,'','Bearer '+token,param).then(item => {
        if(item.error) return false;
        else return item;
    });
};
//export const ESA = (process.env.MODE === "dev") ? "https://auto.test.it.loc/auth/ecaLogin" : "https://auto.privatbank.ua/auth/ecaLogin";
export const ESA = `${process.env.API_URL}/auth/ecaLogin`
export const about_ua = 'https://privatbank.ua/kredity/avto-v-kredit'
export const about_ru = 'https://privatbank.ua/ru/kredity/avto-v-kredit'
export const cookies =
    'https://conditions-and-rules.privatbank.ua/main/view-content-23/'
export const LINK_LEASINGAGREEMENT =
    'https://static.privatbank.ua/files/dogovor-fin-lizing2.pdf'
export const LINK_PAYMENTSCHEDULE =
    'https://static.privatbank.ua/files/grafic-plategey2.pdf'

export const LINK_CREDIT_NEW_CAR =
    'https://privatbank.ua/kredity/kredit-na-avto'
export const LINK_CREDIT_OLD_CAR = 'https://privatbank.ua/kredity/avto-v-kredit'
export const LINK_PERSONAL_INFORMATION =
    'https://privatbank.ua/personal-information'

export const access_login = 'secret_name'
export const access_password = 'secret_pass'

//export const messge_share_url = "https://auto.privatbank.ua/";
export const messge_share_url = `${process.env.API_URL}/`
//export const access_url = (process.env.MODE === "dev") ? "https://auto.test.it.loc/api/" :"https://auto.privatbank.ua/api/";
export const access_url = `${process.env.API_URL}/api/`
//export const caronCredit_url = (process.env.MODE === "dev") ? "https://caroncredit.test.it.loc/api/" :"https://caroncredit.privatbank.ua/api/";
export const caronCredit_url = process.env.CARONCREDIT_URL
export const access_key = '132dcdb9-55a3-46e3-be60-1bbae054d8d1'
export const banner_url_ua = 'https://privatbank.ua/kredity/avto-v-kredit'
export const banner_url_ru = 'https://privatbank.ua/ru/kredity/avto-v-kredit'

//nohup ~/noderun.sh 2>&1 > log.txt &
//lsof -i :3002
